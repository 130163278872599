<template>
    <video controls class="riddle-description-video">
        <source :src="video" type="video/mp4" />
    </video>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps({
    video: String,
})

const { video } = toRefs(props)
</script>

<style scoped>
video {
    max-width: 800px;
    width: calc(100vw - 20px);
    border-radius: 9px;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
