<template>
    <div></div>
</template>

<script setup>
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { useStore } from 'vuex'

const store = useStore()

onMounted(() => {
    store.commit({ type: `updateDisableRiddleInput`, disableRiddleInput: true })
})

onUnmounted(() => {
    store.commit({
        type: `updateDisableRiddleInput`,
        disableRiddleInput: false,
    })
})
</script>

<style></style>
