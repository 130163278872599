<template>
    <div class="view">
        <DisableInput />
        <div class="text-2xl text-white">
            {{ $t('Views.Game.Tutorial.Title') }}
        </div>
        <button class="text-2xl text-orange btn">
            {{ $t('Views.Game.Tutorial.Labels[0]')}}
        </button>
<div class="spacer"></div>
        <div class="center">
            <div class="flex flex-row justify-center">
                <div class="text-normal text-white">
                    {{ $t('Views.Game.Tutorial.MiniLabels[0]') }}
                </div>
                <div class="text-normal text-white">
                    {{ $t('Views.Game.Tutorial.Texts[0]') }}
                </div>
            </div>
            <div class="flex flex-row justify-center">
                <div class="text-normal text-white">
                    {{ $t('Views.Game.Tutorial.MiniLabels[1]') }}
                </div>
                <div class="text-normal text-white">
                    {{ $t('Views.Game.Tutorial.Texts[1]') }}
                </div>
            </div>
            <div>
                <img class="tutorial-img" :src="Tutorial1" alt="">
                <div class="flex justify-center">
                    <div class="text-normal text-orange">
                        {{ $t('Views.Game.Tutorial.MiniLabels[2]') }}
                    </div>
                    <div class="text-normal text-orange">
                        {{ $t('Views.Game.Tutorial.MiniLabels[3]') }}
                    </div>
                    <div class="text-normal text-orange">
                        {{ $t('Views.Game.Tutorial.MiniLabels[4]') }}
                    </div>
                </div>
            </div>
        </div>
<div class="spacer-xl"></div>
        <div class="text-xl text-white">
            {{ $t('Views.Game.Tutorial.Texts[2]') }}
        </div>
        <div class="text-xl text-white">
            {{ $t('Views.Game.Tutorial.Texts[3]') }}
        </div>
        <div class="spacer"></div>
        <button class="text-2xl btn text-orange">
            {{ $t('Views.Game.Tutorial.Labels[1]') }}
        </button>
        <div class="spacer"></div>
        <div class="flex flex-col">
            <div class="text-normal text-white">
                {{ $t('Views.Game.Tutorial.Texts[4]') }}
            </div>
            <div>
                <img class="tutorial-img" :src="Tutorial2" alt="">
                <div class="text-normal text-white">
                    {{ $t('Views.Game.Tutorial.Texts[6]') }}
                </div>
            </div>
            <div class="text-normal text-white">
                {{ $t('Views.Game.Tutorial.Texts[5]') }}
            </div>
        </div>
        <div class="spacer-xl"></div>
        <btn class="text-2xl btn text-orange">
            {{ $t('Views.Game.Tutorial.Labels[2]') }}
        </btn>
        <div class="spacer-xl"></div>
        <div class="text-xl text-white">
            {{ $t('Views.Game.Tutorial.Texts[7]') }}
        </div>
        <div class="spacer"></div>
        <img class="tutorial-img" :src="Tutorial4" alt="">
        <div class="text-xl text-white">
            {{ $t('Views.Game.Tutorial.Texts[8]') }}
        </div>
        <div class="spacer-xl"></div>
        <img class="tutorial-img" :src="Tutorial3" alt="">
        <div class="spacer-xl"></div>
        <btn class="text-2xl btn text-orange">
            {{ $t('Views.Game.Tutorial.Labels[3]') }}
        </btn>
        <div class="spacer-xl"></div>
        <div class="flex flex-col">
            <div class="text-normal text-white">
                {{ $t('Views.Game.Tutorial.Texts[9]') }}
            </div>
            <div class="text-normal text-white">
                {{ $t('Views.Game.Tutorial.Texts[10]') }}
            </div>
        </div>
        <div class="spacer-xl"></div>
        <div class="text-xl text-orange">
            {{ $t('Views.Game.Tutorial.Texts[11]') }}
        </div>
        
        <!-- prettier-ignore -->
        <router-link class="button-tutorial button-grab map-link" to="/game/map">{{ $t('Views.Game.Tutorial.Labels[4]') }}</router-link>
    </div>
</template>

<script>
import {defineComponent, onMounted} from '@vue/runtime-core'
import {useStore} from "vuex"
import DisableInput from '../../components/Game/DisableInput.vue'
import * as tutorialImage from '@/assets/tutorial.jpg'
import * as Tutorial1 from '@/assets/tutorial/Tutorial1.jpg'
import * as Tutorial2 from '@/assets/tutorial/Tutorial2.jpg'
import * as Tutorial3 from '@/assets/tutorial/Tutorial3.jpg'
import * as Tutorial4 from '@/assets/tutorial/Tutorial4.jpg'
import {useApi} from '@/lib/useApi'

export default defineComponent({
    setup() {
        const store = useStore()

        onMounted(() => {
            useApi('forceSolveRiddle', {
                uuid: store.state.game.currentGame.uuid,
                currentPage: window.location.pathname,
            })
        })

        return { tutorialImage, Tutorial1, Tutorial2, Tutorial3, Tutorial4 }
    },
    components: { DisableInput },
})
</script>

<style>
.spacer {
    margin-top: 15px;
    margin-bottom: 15px;
}
.spacer-xl {
    margin-top: 45px;
    margin-bottom: 45px;
}
.justify-center {
    justify-content: center;
}
.center {
    text-align: center;
}
.flex {
    display: flex;
    gap: 5px;
}
.flex-col {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.tutorial-img {
    max-width: 60vw;
    width: 100%;
    height: auto;
}
.flex-row .tutorial-img {
    max-width: 30vw;
}
.game-view {
    overflow: auto;
}
.button-tutorial {
    font-size: 36px;
    color: #ea872f;
    padding: 15px 5px;
}
.view {
    background: #000;
    text-align: center;
}
.flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}

.text-2xl {
    font-size: 32px;
}
.text-xl {
    font-size: 26px;
}
.text-normal {
    font-size: 18px;
}
.text-white {
    color: #fff;
}
.text-orange {
    color: #ea872f
}
.btn {
    background-color: #000;
    border: 1px solid #ea872f;
    padding: 15px 35px;
    cursor: pointer;
}
</style>
