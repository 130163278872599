<template>
    <DisableInput />
    <img class="desc-image" :src="descImg" alt="" />
    <div
        class="ending-view"
        v-if="!ending.gift && !ending.sell && !ending.keep"
    >
        <div class="part">
            <!-- <RiddleDescriptionSound :sound="sound1" />
            <p>{{ $t('Views.Game.Finished.Descriptions[0]') }}</p> -->
            <RiddleDescriptionVideo :video="video1" />
        </div>
        <div class="sol-section">
            <img class="sol-image" :src="solImg" alt="" />
            <router-link class="btn-finished btn-1" :to="`/game/credits`">Verkaufen</router-link>
            <router-link class="btn-finished btn-2" :to="`/game/credits`">Behalten</router-link>
        </div>
    </div>

    <div v-if="ending.sell">
        <RiddleDescriptionSound :sound="sound4" />
        <img :src="bild" alt="Bild" />
        <p>{{ $t(`Views.Game.Finished.Texts[0]`) }}</p>
        <RiddleDescriptionVideo :video="video2" />
        <br />
        <button class="btn-finished">
            <router-link :to="`/game/credits`">
                {{ $t(`Views.Game.Finished.FinishButton`) }}
            </router-link>
        </button>
    </div>
    <div v-if="ending.keep">
        <RiddleDescriptionSound :sound="sound5" />
        <img :src="bild" alt="Bild" />
        <p>{{ $t(`Views.Game.Finished.Texts[1]`) }}</p>
        <RiddleDescriptionVideo :video="video3" />
        <br />
        <button class="btn-finished">
            <router-link :to="`/game/credits`">
                {{ $t(`Views.Game.Finished.FinishButton`) }}
            </router-link>
        </button>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import DisableInput from '../../components/Game/DisableInput.vue'
import RiddleDescriptionSound from '../../components/Game/RiddleDescriptionSound.vue'

import descImg from '@/assets/finished/background.jpg'
import solImg from '@/assets/finished/background-finished.png'
import * as bild from '@/assets/finished/jesus.jpg'

import * as sound1 from '@/assets/finished/sound1.mp3'
import * as sound2 from '@/assets/finished/sound2.mp3'
import * as sound3 from '@/assets/finished/sound3.mp3'
import * as sound4 from '@/assets/finished/sound4.mp3'

import * as video1 from '@/assets/finished/video1.mp4'
import * as video2 from '@/assets/finished/video2.mp4'
import * as video3 from '@/assets/finished/video3.mp4'

import RiddleDescriptionVideo from '../../components/Game/RiddleDescriptionVideo.vue'

export default defineComponent({
    setup() {
        const store = useStore()
        onMounted(() => {
            if (store.getters.allRiddlesSolved()) {
                store.commit('updateSynced', 0)
            }
        })
        const ending = ref({
            sell: false,
            keep: false,
        })
        const choseEnding = (chosen) => {
            ending.value[chosen] = true
        }
        return {
            ending,
            choseEnding,
            descImg,
            bild,
            sound1,
            sound2,
            sound3,
            sound4,
            video1,
            video2,
            video3,
            solImg,
        }
    },
    components: {
        DisableInput,
        RiddleDescriptionSound,
        RiddleDescriptionVideo,
    },
})
</script>

<style scoped>
.desc-image {
    z-index: -1;
    max-height: calc(100vh - 40px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ending-view {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.abteile {
    display: flex;
    justify-content: space-around;
    gap: 15px;
}
.abteil {
    border: 1px solid var(--base-black);
    padding: 15px 25px;
    border-radius: 7px;
}
.btn-finished a {
    color: white;
    text-decoration: none;
}
.btn-finished a:visited {
    color: white;
    text-decoration: none;
}
.sol-section{
    position: relative;
    width: fit-content;
    height: fit-content;
}
.sol-section .btn-1{
    position: absolute;
    top: 84%;
    left: 26%;
    font-weight: bold;
    font-size: 18px;
    color: #4c0b3b;
    background: #b89bb2;
}
.sol-section .btn-2{
    position: absolute;
    top: 84%;
    left: 64.5%;
    font-weight: bold;
    font-size: 18px;
    color: #4c0b3b;
    background: #b89bb2;
}
.sol-image{
    width: 95%;
    max-width: 1000px;
    max-height: 700px;
    margin: auto;
    border-radius: 7px;
}
</style>
