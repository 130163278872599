<template>
    <audio controls class="riddle-description-sound">
        <source :src="sound" type="audio/mp3" />
    </audio>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps({
    sound: String,
})

const { sound } = toRefs(props)
</script>

<style scoped>
audio {
    border-radius: 9px;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
