<template>
    <div>
        <div
            v-if="!store.state.game.currentGame.descriptionRead"
            class="riddle-description"
        >
            <img class="desc-image" :src="descImg" alt="" />
            <p>
                {{ $t('Views.Game.Karte.Text') }}
                <br><br>
                {{ $t('Views.Game.Karte.Text2') }}
                <br><br>
                {{ $t('Views.Game.Karte.Text3') }}
            </p>
            <div class="section">
                <img class="preview" :src="EinleitungImg" alt="" />
            </div>
            <button
                @click="
                    store.commit({
                        type: 'updateDescriptionRead',
                        descriptionRead: true,
                    })
                "
            >
                {{ $t('Utilities.Riddles.DescriptionReadButton') }}
            </button>
        </div>
        <DisableInput />
        <div class="riddle" v-if="store.state.game.currentGame.descriptionRead">
            <img class="riddle-img" :src="map" alt="Karte" />
            <svg
                class="riddle-svg"
                viewBox="0 0 2049 1537"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    :class="btnClasses.kirche"
                    @click="linkToStrangKirche"
                    d="M129.911 1004.15L144.114 923.934L153.121 1004.15L161.436 1009.66V1024.11L157.971 1052.34L169.057 1071.97L213.5 1075.43L263.5 1079.43L282.686 1103.43V1135.93L276.5 1144.26L244.579 1146.33L223.446 1152.87L181.875 1149.08L170.443 1154.93L122.636 1146.33L97 1144.26L102 1071.97L117.786 1041.67L114.668 1021.36L122.636 1007.25L129.911 1004.15Z"
                />
                <path
                    :class="btnClasses.schloss"
                    @click="linkToStrangSchloss"
                    d="M1692.47 670.077V657.01L1701.02 657.68L1701.68 665.386L1718.45 655L1775 699.895L1771.38 748.476L1775 782.65L1739.16 786L1705.29 784.66L1630 775.614V762.882H1642.82L1640.52 725.358L1660.91 715.307L1656.63 692.524L1692.47 670.077Z"
                />
                <path
                    :class="btnClasses.obelisk"
                    @click="linkToStrangObelisk"
                    d="M1416.26 416.418L1424.68 412L1431.85 416.418L1444.01 536.052L1449 550.327L1448.06 560.863L1431.85 568H1406.29L1396 560.863L1403.17 499.686L1407.54 469.778L1416.26 416.418Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    watchEffect,
} from '@vue/runtime-core'
import * as map from '@/assets/map.jpg'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DisableInput from '../../components/Game/DisableInput.vue'
import * as descImg from '@/assets/hintergrund.jpg'
import * as EinleitungImg from '@/assets/Einleitung.jpg'

export default defineComponent({
    setup() {
        const store = useStore()
        const router = useRouter()
        onMounted(() => {
            //console.log(store.getters.allRiddlesSolved())
            if (!store.getters.allRiddlesSolved()) return
            // TEMP
            // var input = document.createElement('input')
            // input.setAttribute('name', 'code')
            // input.setAttribute('value', store.state.shop.accessCode)
            // input.setAttribute('type', 'hidden')
            // var form = document.createElement('form')
            // form.setAttribute('method', 'POST')
            // form.setAttribute(
            //     'action',
            //     'https://${process.env.VUE_APP_URL}/fragebogen.php'
            // )
            // form.appendChild(input)

            // document.body.appendChild(form)
            // form.submit()

            router.push('/game/finished')
        })
        watchEffect(() => {
            if (!store.getters.allRiddlesSolved()) return
            // TEMP
            var input = document.createElement('input')
            input.setAttribute('name', 'code')
            input.setAttribute('value', store.state.shop.accessCode)
            input.setAttribute('type', 'hidden')
            var form = document.createElement('form')
            form.setAttribute('method', 'POST')
            form.setAttribute(
                'action',
                'https://${process.env.VUE_APP_URL}/fragebogen.php'
            )
            form.appendChild(input)

            document.body.appendChild(form)
            // form.submit()

            router.push('/game/finished')
        })
        const riddlePathSolved = computed(() => {
            return {
                kirche: store.getters.isRiddleSolved('/game/kirche/wegweiser'),
                obelisk: store.getters.isRiddleSolved(
                    '/game/obelisk/alexander_schulte'
                ),
                schloss: store.getters.isRiddleSolved('/game/schloss/adler'),
            }
        })
        // Dynamische klassen, damit Wege als finished markiert werden können.
        const btnClasses = computed(() => {
            return {
                kirche: `btn-svg ${
                    riddlePathSolved.value.kirche ? 'solved' : ''
                }`,
                obelisk: `btn-svg ${
                    riddlePathSolved.value.obelisk ? 'solved' : ''
                }`,
                schloss: `btn-svg ${
                    riddlePathSolved.value.schloss ? 'solved' : ''
                }`,
            }
        })
        // Links bekommen guard clauses, damit disabled links nicht mehr funktionieren
        const linkToStrangKirche = () => {
            if (riddlePathSolved.value.kirche) return false
            router.push('/game/kirche/einleitung')
        }
        const linkToStrangObelisk = () => {
            if (riddlePathSolved.value.obelisk) return false
            router.push(`/game/obelisk/einleitung`)
        }
        const linkToStrangSchloss = () => {
            if (riddlePathSolved.value.schloss) return false
            router.push('/game/schloss/einleitung')
        }
        return {
            map,
            store,
            riddlePathSolved,
            btnClasses,
            descImg,
            EinleitungImg,
            linkToStrangKirche,
            linkToStrangObelisk,
            linkToStrangSchloss,
        }
    },
    components: { DisableInput },
})
</script>

<style scoped>
.preview{
    height: 40vh;
    max-width: 500px;
    max-height: 500px;
    margin: auto;
    border-radius: 7px;
    z-index: 2;
}
.section{
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 2;
}
.riddle-description {
    position: relative;
    height: calc(100vh - 90px);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    color: #333;
}
.solved {
    fill-opacity: 0.5;
    cursor: not-allowed;
}
.riddle-description p{
    max-width: 800px;
}
p{
    background: rgba(132, 93, 39, 0.60);
}
</style>
<style>
.game-view {
    overflow: none;
}
</style>